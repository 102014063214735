<template>
  <div class="info_warp">
    <div class="wrap">
      <div class="head_img">
        <img :src="form.avatar"
             v-if="form.avatar"
             @click="uploadImage"
             alt="">
        <img src="@/assets/personalCenter/头像.png"
             @click="uploadImage"
             v-else
             alt="">
      </div>

      <el-form :model="form"
               label-width="170rem">
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名：">
              <el-input placeholder="请输入姓名"
                        v-model="form.truename"
                        readonly
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="准考证号：">
              <el-input v-model="form.admission_ticket"
                        readonly
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="昵称：">
              <el-input placeholder="请输入昵称"
                        readonly
                        v-model="form.nickname"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="性别："
                          style="position:relative">
              <el-select v-model="form.sex_id"
                         clearable
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in sexList"
                           :key="item.id"
                           :label="item.sex_name"
                           :value="item.id">
                </el-option>
              </el-select>
              <div class="readonly"></div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="年级：">
              <el-select v-model="form.edu_grade_id"
                         clearable
                         @change="getClass"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="btn"
                 @click="save">
              确 认
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>



<script>
import { getUserInfo, editUserInfo } from '@/api/userInfo.js'
import { uploadImageAuto } from '@/api/upload.js'
import { hasClass } from '@/api/class.js'
import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      form: {
        sex_id: '',
        avatar: '',
        truename: '',
        nickname: '',
        admission_ticket: ''
      },
      sexList: [
        { id: 1, sex_name: '男' },
        { id: 2, sex_name: '女' },
        { id: 3, sex_name: '保密' },
      ],
      is_retail: 0,
      gradeList: []
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    ...mapMutations(['CLEAR_SUBJECT']),
    async getInfo () {
      this.is_retail = JSON.parse(window.localStorage.getItem('userInfo')).is_retail
      const { data } = await getUserInfo()
      let form = {
        sex_id: data.sex_id,
        avatar: data.avatar,
        truename: data.truename,
        nickname: data.nickname,
        edu_grade_id: data.edu_grade_id,
        admission_ticket: data.admission_ticket

      }
      this.gradeList = data.grade_data
      this.$set(this, 'form', form)
    },
    async uploadImage () {
      const { data } = await uploadImageAuto()
      this.$message.success('上传成功')
      this.form.avatar = data.info.dir
    },
    async save () {
      let form = JSON.parse(JSON.stringify(this.form))
      let params = {
        grade_id: form.edu_grade_id
      }
      // const { data } = await hasClass(params)

      this.CLEAR_SUBJECT([])
      // console.log(data)
      //如果有班级可以修改 先跳转班级回去修改
      console.log('ssssssssssss', form);
      await editUserInfo(form)

      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      userInfo.edu_grade_id = form.edu_grade_id
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$message.success('修改成功')
    },
    async getClass () {
      let form = JSON.parse(JSON.stringify(this.form))
      let params = {
        grade_id: form.edu_grade_id
      }
      // const { data } = await hasClass(params)
      // if (data.list.length) {
      //   this.$router.push('/subjectClass?grade_id=' + params.grade_id)
      //   window.sessionStorage.setItem('user_form', JSON.stringify(form))
      //   this.$message.success('请选择进入的班级')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.info_warp {
  display: flex;
  justify-content: center;
  .wrap {
    width: 572rem;
    .head_img {
      display: flex;
      justify-content: center;
      img {
        width: 160rem;
        height: 160rem;
        border-radius: 50%;
      }
    }
    .el-form {
      margin-top: 50rem;
      ::v-deep .el-form-item__label {
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        line-height: 60rem;
      }
      ::v-deep .el-input--medium .el-input__inner {
        height: 60rem;
        line-height: 60rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
      }
    }

    .btn {
      width: 572rem;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 60rem;
      text-align: center;
    }
  }
}
.readonly {
  width: 100%;
  height: 60rem;
  z-index: 1999;
  position: absolute;
  top: 0;
}
</style>