

import { instance } from '@/utils/http.js'

// 获取还有没有班级
export const hasClass = function (params) {
  return instance({
    url: '/api/v1/user/user_grade_subject_class',
    method: 'get',
    params
  })
}